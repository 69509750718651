<template>
  <div class="preview-box user-actions">
    <div class="preview-box-container">
      <div class="preview-box-title">
        <h2>Actions</h2>
      </div>
      <div
        class="preview-box-content"
      >
        <dl>
          <dt>Customer Sales Process Step</dt>
          <dd>{{customerDetails.sales_process_step >= 0 ? customerDetails.sales_process_step : ''}}
              -
            {{customerDetails.sales_process_step_name ? customerDetails.sales_process_step_name : ''}}
            <ConfirmView
              actionButtonText="Change"
              actionButtonClass="btn btn-sm text"
              :action="actionChangeSalesProcessStep"
              @on-action-done="onActionDone"
            >
              <template v-slot:confirm>
                <select v-model="actionChangeSalesProcessStep.model">
                  <option
                    v-for="group in SalesProcessStep.allItems()"
                    :key="group.value"
                    :value="group.value"
                  >{{group.value}}-{{group.label}}</option>
                </select>
              </template>
            </ConfirmView>
          </dd>
          <dt>Customer Sales Process Notes</dt>
          <dd v-html="customerDetails.sales_process_notes"
            v-if="customerDetails.sales_process_notes_list
            && customerDetails.sales_process_notes_list.length <= 1"></dd>
          <ul class="notes-list" v-else>
            <li v-html="list" v-for="list in customerDetails.sales_process_notes_list" :key="list.key">
            </li>
          </ul>
        </dl>
      </div>
      <hr>
      <div
        class="preview-box-tools"
      >
        <ConfirmView
          actionButtonText="Add Sales Process Notes"
          title="Customer Sales Process Notes"
          :action="actionAddSalesProcessNotes"
          @on-action-done="onActionDone"
        >
          <template v-slot:confirm>
            <textarea
              rows="5"
              v-model="actionAddSalesProcessNotes.model"
            />
          </template>
        </ConfirmView>
      </div>
    </div>
  </div>
</template>
<script>
import {
  ref,
  computed,
} from 'vue';
import { useStore } from 'vuex';
import {
  UsersReport,
  SalesProcessStep,
  AdminAction,
} from '@/store/modules/admin';

import ConfirmView from '@/components/admin/ConfirmView.vue';

export default {

  components: {
    ConfirmView,
  },

  setup() {
    const store = useStore();
    const customerDetails = store.state.admin.previewUserDetails;
    const customerKey = customerDetails.user;

    const actionChangeSalesProcessStep = ref(new AdminAction(
      () => UsersReport.changeSalesProcessStep(customerKey, actionChangeSalesProcessStep.value.model),
      null,
      { model: customerDetails.sales_process_step || '' },
    ));

    const actionAddSalesProcessNotes = ref(new AdminAction(
      () => UsersReport.addSalesProcessNotes(customerKey,
        {
          sales_process_notes: actionAddSalesProcessNotes.value.model,
          customer_key: customerKey,
        }),
      null,
      { model: customerDetails.sales_process_notes || '' },
    ));

    const onUserUpdate = (updatedDetails) => {
      store.commit('admin/updateUserDetails', { details: updatedDetails });
    };

    const onActionDone = (context, updatedDetails) => {
      onUserUpdate(updatedDetails);
    };

    return {
      actionChangeSalesProcessStep,
      actionAddSalesProcessNotes,
      onActionDone,
      SalesProcessStep,
      customerDetails: computed(() => store.state.admin.previewUserDetails),
    };
  },
};
</script>
<style lang="scss" scoped>
.user-actions {
  max-width: size(300);
  margin-left: size(25);
  margin-top: size(30);
}
.notes-list {
  li {
    padding: size(6) 0;
    &:before {
      content: '';
      display: inline-block;
      vertical-align: middle;
      width: size(5);
      height: size(5);
      background: var(--global--white--Color);
      border-radius: 50%;
      margin-right: size(8);
    }
  }
}
</style>
