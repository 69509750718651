<template>
  <div class="item-list invoice-list item-report-summary">
    <div
      class="summary-toolbar"
      v-if="title"
    >
      <h2>{{title}}</h2>
    </div>

    <table class="no-row-border">
      <thead>
        <tr>
          <th>Date</th>
          <th>Project</th>
          <th>Created</th>
          <th>Plan name</th>
          <th>Amount</th>
          <th>Eur</th>
          <th>Payment Method</th>
          <th>A</th>
          <th>E</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in report.items"
          :key="item.key"
          :class="item.project.is_project_deleted ? 'deleted' : ''"
        >
          <td>
            <span
              @click="$emit('on-invoice-click', item, false)"
              v-if="$route.name !== 'adminUserPreview'"
            >
              {{ $filters.relativeShortDate(item.generated_date) }}
            </span>
            <span
              @click="$emit('on-invoice-click', item)"
              v-else
            >
              {{ $filters.timeStamp(item.generated_date) }}
            </span>
          </td>
          <td>
            <span
              @click="$emit('on-invoice-click', item, true)"
            >
              {{item.project.name}}
            </span>
          </td>
          <td>{{ (item && item.project_raw && item.project_raw.created)
            ? $filters.formatDate(item.project_raw.created) : '-'}} </td>
          <td>
            <div v-if="item.subtitle" class="hover-wrapper">
              <span>{{item.subtitle.split('/')[0]}}</span>
              <span class="hover-element">{{item.subtitle.split('/').slice(1).join('/')}}</span>
            </div>
            <span v-else>-</span>
          </td>
          <td>{{formatCurrency(item.grand_total, item.currency, true)}}</td>
          <td>{{formatCurrency(item.eur, 'eur', true)}}</td>
          <td>{{item.payment_gateway}} | {{item.payment_medium}}</td>
          <td>
            <span v-if="item.is_verified">
              <svg>
                <use v-bind="{ 'xlink:href': '#tickmark-icon'}" />
              </svg>
            </span>
            <span v-else> - </span>
          </td>
          <td>
            <span v-if="item.email_sent">
              <svg>
                <use v-bind="{ 'xlink:href': '#tickmark-icon'}" />
              </svg>
            </span>
            <span v-else> - </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { currencyMethodsMixin } from '@/mixin/currency';

export default {
  props: {
    report: Object,
    title: {
      type: String,
      default: '',
    },
  },
  mixins: [currencyMethodsMixin],
};
</script>
<style lang="scss" scoped>
.item-report-summary {
  .no-row-border {
    tr {
      td {
        .hover-wrapper {
          position: relative;
          &:hover {
            .hover-element {
              display: block;
            }
          }
          .hover-element {
            padding: size(4);
            border-radius: size(6);
            background: var(--admin--color-level4);
            position: absolute;
            top: size(-15);
            font-size: size(12);
            line-height: size(16);
            display: none;
            right: size(10);
            z-index: 10;
          }
        }
      }
    }
  }
}
</style>
