<template>
  <div class="user-project-table">
    <div>
      <div class="toolbar">
        <div class="title">
          <h1>Projects</h1>
        </div>
        <div class="tools">
        </div>
      </div>
    </div>
    <rz-request-state :state="state" />
    <div
      class="usage-report admin-screen-content"
      v-if="state.status.isLoaded"
    >
      <FilterableView
        :filterables="filterables"
        @item-select="onSelectedFilteritem"
        @item-toggle="onFilterItemToggle"
      >
        <div class="item-list-row item-list">
          <div>
            <UserProjectsList
              :title="title"
              :total="totalItems"
              :projects="filteredProjects"
              @on-project-click="showPreviewProject"
            />

            <div
              v-if="pages"
              class="filter-pagination"
            >
              <ul>
                <li
                  v-for="page in pages"
                  :key="page"
                >
                  <router-link
                    class="btn"
                    :to="{query: {...$route.query, page: page}}"
                  >{{page}}</router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </FilterableView>
    </div>

    <QuickPreviewView
      v-if="previewProject && previewProject.key"
    >
      <template v-slot:left>
        <ul class="item-box">
          <li
            v-for="project in filteredProjects"
            :key="project.key"
            @click="showPreviewProject(project)"
            :class="{active: project.key === previewProject.key}"
          >
          {{project.name}}
          </li>
        </ul>
      </template>
      <template v-slot:title>
        <h1>{{previewProject.name}}</h1>
        <div class="tools">
          <ProjectItemLicense :project="previewProject" largeStyle/>
          <router-link
            class="btn"
            :to="{name: 'ProjectPage', params: {projectKey: previewProject.key}}"
          >Open</router-link>
          <button
            @click="hidePreviewProject"
            class="btn"
          >X</button>
        </div>
      </template>
      <template v-slot:content>
        <ProjectPreview
          :projectKey="previewProject.key"
          @update="onPreviewUpdate"
        />
      </template>
    </QuickPreviewView>
  </div>
</template>
<script>
import {
  ref,
  onMounted,
  watch,
  computed,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  UsersReport,
  Filterables,
} from '@/store/modules/admin';
import FilterableView from '@/components/admin/Filterable.vue';
import ProjectPreview from '@/components/admin/ProjectPreview.vue';
import ProjectItemLicense from '@/components/admin/ProjectItemLicense.vue';
import QuickPreviewView from '@/components/admin/QuickPreviewView.vue';
import UserProjectsList from '@/components/admin/UserProjectsList.vue';

export default {
  components: {
    FilterableView,
    QuickPreviewView,
    ProjectPreview,
    UserProjectsList,
    ProjectItemLicense,
  },
  props: {
    customerDetails: Object,
  },
  setup(props) {
    const store = useStore();
    const state = ref(new RequestState());
    const report = ref({});
    const pageLimit = 150;
    const activeItems = ref([]);
    const filterables = ref(new Filterables({ items: [] }));
    const route = useRoute();
    const projects = ref({});
    const previewProject = ref({});
    const filteredProjects = ref([]);
    const itemGroup = ref(route.query.group || 'active');
    const title = ref('Projects');
    const page = ref(1);

    const showPreviewProject = (project) => {
      previewProject.value = project;
    };
    // Need this function to load project using query param project key
    // eslint-disable-next-line no-unused-vars

    const hidePreviewProject = () => {
      previewProject.value = {};
      store.commit('admin/updatePreviewUsersProject', { project: null });
      return false;
    };
    const fetchProjectData = () => {
      state.value.status = RequestStateStatus.Loading;
      const params = {
        limit: pageLimit,
        page: page.value,
      };
      return UsersReport.filterProjectsQuery(props.customerDetails, params).then((projectList) => {
        projects.value = projectList;
        filteredProjects.value = [...projectList.projects];
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };
    const onSelectedFilteritem = () => {
    };
    watch(() => props.customerDetails, () => {
      fetchProjectData();
    });
    onMounted(() => {
      fetchProjectData();
    });
    const totalItems = computed(() => {
      if (projects.value.page && projects.value.page.total_items) {
        return `${projects.value.page.total_items}`;
      }
      let length = `${projects.value.projects.length}`;
      if (projects.value.page && projects.value.page.total_pages > 1) {
        length = `${length}+`;
      }
      return length;
    });
    const pages = computed(() => {
      if (projects.value.page && projects.value.page.total_pages > 1) {
        const currentPage = parseInt(page.value, 10);
        const total = projects.value.page.total_pages;
        const maxPageItems = 10;
        const items = [];
        let left = 0;
        let right = Math.min(total, maxPageItems);
        if (total > maxPageItems) {
          left = Math.max(0, currentPage - (maxPageItems / 2));
          right = left + maxPageItems - 1;
        }
        for (let i = left; i < right; i += 1) {
          items.push(i + 1);
        }
        if (items[0] !== 1) {
          items.splice(0, 0, 1);
        }
        if (items[items.length - 1] !== total) {
          items.push(total);
        }
        return items;
      }
      return null;
    });
    const onPreviewUpdate = (project) => {
      previewProject.value = project;
    };
    return {
      state,
      report,
      filterables,
      activeItems,
      projects,
      pages,
      totalItems,
      title,
      filteredProjects,
      onSelectedFilteritem,
      itemGroup,
      onPreviewUpdate,
      showPreviewProject,
      hidePreviewProject,
      previewProject,
    };
  },
};
</script>
