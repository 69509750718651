<template>
  <div class="projects-info-list item-list item-report-summary">
    <div
      class="summary-toolbar"
      v-if="title"
    >
      <h2>{{title}} ({{total}})</h2>
    </div>

    <table class="no-row-border">
      <thead>
        <tr>
          <th>Project</th>
          <th>Company</th>
          <th>Li.</th>
          <th>V.</th>
          <th>V. Req.</th>
          <th>Trail</th>
          <th>Status</th>
          <th>Bil.</th>
          <th>Created</th>
          <th>P</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="project in projects"
          :key="project.key"
          :class="[project.pending_status && $route.query.group !== 'pending'
          ? 'pending': '']"
        >
          <td>
            <ProjectItemName :project="project"
            @on-project-click="$emit('on-project-click', project)" />
          </td>
          <td>
            <ProjectItemCompanyName :project="project" />
          </td>
          <td>
            <div class="project-item-license">
              <span
                v-if="project.project_license[0].product === 'rs_paygo_standard'"
              >{{largeStyle ? project.project_license.name : 'S'}}</span>
              <span
                v-else-if="project.project_license[0].product === 'rs_paygo_enterprise_a101'"
              >{{largeStyle ? project.project_license.name : 'E'}}</span>
              <span
                v-else
                class="unknown"
              >{{project.project_license.name}}</span>
            </div>
          </td>
          <td>{{project.billing && project.billing.is_verified
             ? '✅': 'No'}}</td>
          <td>{{project.billing &&
            !project.billing.is_draft &&
            !project.billing.is_verified ? '✅': 'No'}}</td>
          <td>{{project.group === 'customer_trail_project' ? '✅' : 'No'}}</td>
          <td>{{project.status ? project.status : '-'}}</td>
          <td v-if="project && project.billing">{{project.billing && project.billing.preferred_payment_gateway
            && project.billing.preferred_payment_medium
            ? ((project.billing.preferred_payment_gateway[0].toUpperCase())+'-'
            +(project.billing.preferred_payment_medium[0].toUpperCase()))
            : '-'}}</td>
          <td>{{ $filters.timeStamp(project.created) }}</td>
          <td>
            <svg v-if="project.pending_status &&
            project.pending_status === 'payment_pending'">
              <use v-bind="{ 'xlink:href': '#payment-pending-icon' }" />
            </svg>
            <span v-else-if="project.pending_status &&
            project.pending_status !== 'payment_pending'">
              {{project.pending_status}}
            </span>
            <span v-else> - </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import ProjectItemCompanyName from './ProjectItemCompanyName.vue';
import ProjectItemName from './ProjectItemName.vue';

export default {
  props: {
    projects: Object,
    title: {
      type: String,
      default: '',
    },
    total: {
      type: String,
      default: '',
    },
    largeStyle: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ProjectItemCompanyName,
    ProjectItemName,
  },
};
</script>
