<template>
  <QuickPreviewView
    v-if="previewProject && previewProject.user"
  >
    <template v-slot:left>
      <ul class="item-box" v-if="!$route.query.search">
        <li
          v-for="project in filteredProjects"
          :key="project.key"
          :class="{active: project.user === previewProject.user}"
          @click="showPreviewProject(project)"
        >
          {{project.name}}
        </li>
      </ul>
      <ul class="item-box" v-else>
        <li
          v-for="project in filteredProjects"
          :key="project.key"
          :class="{active: project.key === previewProject.user}"
          @click="showPreviewProject(project)"
        >
          {{project.name}}
        </li>
      </ul>
    </template>
    <template v-slot:title>
      <h1>{{previewProject.name}}</h1>
      <div class="tools">
        <button
        @click="hidePreviewProject"
        class="btn"
        >X</button>
      </div>
    </template>
    <template v-slot:content>
      <UsersPreview :customerDetails="previewProject"
        @update="onPreviewUpdate"/>
    </template>
  </QuickPreviewView>
</template>
<script>
import {
  ref,
  onMounted,
  watch,
} from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  UsersReport,
  ProjectList,
} from '@/store/modules/admin';
import QuickPreviewView from '@/components/admin/QuickPreviewView.vue';
import UsersPreview from '@/views/admin/UsersPreview.vue';

export default {
  components: {
    QuickPreviewView,
    UsersPreview,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const state = ref(new RequestState());
    const projects = ref({});
    const previewProject = ref({});
    const filteredProjects = ref([]);
    const itemGroup = ref(route.query.group || 'active');

    const showPreviewProject = (project) => {
      previewProject.value = project;
      store.commit('admin/updateUserDetails', { details: project });
      if (route.query.search) {
        router.push({
          name: 'adminUserPreview',
          params: { key: project.key },
          query: { search: route.query.search },
        });
      } else {
        router.push({
          name: 'adminUserPreview',
          params: { key: project.user },
          query: {
            limit: route.query.limit,
            page: route.query.page,
          },
        });
      }
    };

    const hidePreviewProject = () => {
      previewProject.value = {};
      router.push({
        name: 'adminUsersInfo',
      });
      store.commit('admin/updatePreviewUser', { project: null });
      return false;
    };

    const fetchCustomer = () => {
      let params = {};
      params = {
        limit: route.query.limit,
        page: route.query.page,
        ...params,
      };
      UsersReport.fetchCustomerData(route.params.key, params).then((response) => {
        store.commit('admin/updateUserDetails', { details: response });
        previewProject.value = response;
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const fetchData = () => {
      state.value.status = RequestStateStatus.Loading;
      let params = {};
      params = {
        limit: route.query.limit,
        page: route.query.page,
        ...params,
      };

      if (route.query.search) {
        return ProjectList.searchQuery(route.query.search, 'user').then((list) => {
          filteredProjects.value = list.data.data.docs;
        });
      }
      return UsersReport.filterQuery(params).then((customerList) => {
        projects.value = customerList;
        filteredProjects.value = [...customerList.customers];
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    watch(() => route.params.key, () => {
      if (route.params.key && route.query.search) {
        fetchCustomer();
        fetchData();
      }
    });
    onMounted(() => {
      if (route.params.key && route.query) {
        fetchCustomer();
        fetchData();
      }
    });
    return {
      state,
      projects,
      filteredProjects,
      itemGroup,
      showPreviewProject,
      hidePreviewProject,
      previewProject,
    };
  },
};
</script>
