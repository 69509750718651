<template>
  <div class="screen-admin-page screen-admin-usaga-report user-preview">
    <UserProjectTable  :customerDetails="customerDetails" />
    <UserInvoiceTable  :customerDetails="customerDetails" />
    <UserPreviewActions />
  </div>
</template>
<script>
import UserInvoiceTable from '@/components/admin/UserInvoiceTable.vue';
import UserProjectTable from '@/components/admin/UserProjectTable.vue';
import UserPreviewActions from '@/components/admin/UserPreviewActions.vue';

export default {
  components: {
    UserInvoiceTable,
    UserProjectTable,
    UserPreviewActions,
  },
  props: {
    customerDetails: Object,
  },
};
</script>
