<template>
    <div class="screen-admin-page screen-admin-usaga-report">
      <div>
        <div class="toolbar">
          <div class="title">
            <h1>Invoices</h1>
          </div>
        </div>
      </div>
      <rz-request-state :state="state" />
      <div
        class="usage-report admin-screen-content"
        v-if="state.status.isLoaded"
      >
        <FilterableView
          :filterables="filterables"
          @item-select="onSelectedFilteritem"
          @item-toggle="onFilterItemToggle"
        >
          <div
            v-if="filterables.activeItem === 'paidInvoices'"
            class="item-list-row item-list"
          >
            <InvoiceReportTable
              :report="report.fulfilled"
              @on-invoice-click="showPreviewInvoice"
              title="Paid Invoices"
            />
          </div>
          <div
            v-if="filterables.activeItem === 'unpaidInvoices'"
            class="item-list-row item-list"
          >
            <InvoiceReportTable
              :report="report.notFulfilled"
             @on-invoice-click="showPreviewInvoice"
              title="Unpaid Invoices"
            />
          </div>
          <div
            v-if="filterables.activeItem === 'cancelledInvoices'"
            class="item-list-row item-list"
          >
            <InvoiceReportTable
              :report="report.cancelled"
              @on-invoice-click="showPreviewInvoice"
              title="Cancelled Invoices"
            />
          </div>
          <div
            v-if="filterables.activeItem === 'approvedInvoices'"
            class="item-list-row item-list"
          >
            <InvoiceReportTable
              :report="report.approved"
              @on-invoice-click="showPreviewInvoice"
              title="Approved Invoices"
            />
          </div>
        </FilterableView>
      </div>
      <QuickPreviewView
        v-if="previewInvoice && previewInvoice.key"
        >
        <template v-slot:left>
          <ul class="item-box">
          <template v-if="!$route.query.showProjectInfo">
            <li
              v-for="invoice in activeItems"
              :key="invoice.key"
              @click="showPreviewInvoice(invoice)"
              :class="{active: invoice.key === previewInvoice.key}"
            >
            {{invoice.project.name}}
            </li>
          </template>
          <template v-else>
            <li
              v-for="invoice in activeItems"
              :key="invoice.key"
              @click="showPreviewInvoice(invoice , true)"
              :class="{active: invoice.key === previewInvoice.key}"
            >
            {{invoice.project.name}}
            </li>
          </template>
          </ul>
        </template>
        <template v-slot:title>
          <h1>{{formatCurrency(previewInvoice.grand_total, previewInvoice.currency, true)}}
          - {{previewInvoice.project.name}}</h1>
          <div class="tools" v-if="$route.query.showProjectInfo">
            <router-link
              class="btn"
              :to="{name: 'ProjectPage', params: {projectKey: previewInvoice.project.key}}"
            >Open</router-link>
            <button
              @click="hidePreviewInvoice"
              class="btn"
            >X</button>
          </div>
          <div class="tools" v-else>
            <router-link
              class="btn"
              :to="{
                name: 'invoice',
                params: { invoicekey: previewInvoice.key, projectKey: previewInvoice.project.key } }"
            >Open</router-link>
            <button
              @click="hidePreviewInvoice"
              class="btn"
            >X</button>
          </div>
        </template>
        <template v-slot:content>
          <InvoicePreview
          :invoiceKey="previewInvoice.key"
          :info="previewInvoice"
          />
        </template>
      </QuickPreviewView>
    </div>
</template>
<script>
import moment from 'moment';
import {
  ref,
  onMounted,
  watch,
} from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { RequestState, RequestStateStatus } from '@/api/core';
import {
  UsersReport,
  FilterableItem,
  Filterables,
} from '@/store/modules/admin';
import { currencyMethodsMixin } from '@/mixin/currency';
import FilterableView from '@/components/admin/Filterable.vue';
import InvoiceReportTable from '@/components/admin/InvoiceReportTable.vue';
import QuickPreviewView from '@/components/admin/QuickPreviewView.vue';
import InvoicePreview from '@/components/admin/InvoicePreview.vue';

export default {
  mixins: [currencyMethodsMixin],
  components: {
    FilterableView,
    InvoiceReportTable,
    QuickPreviewView,
    InvoicePreview,
  },
  props: {
    customerDetails: Object,
  },
  setup(props) {
    const store = useStore();
    const route = useRoute();
    const monthFromQuery = () => {
      if (route.query.month) {
        return moment(route.query.month);
      }
      return null;
    };
    const state = ref(new RequestState());
    const report = ref({});
    const filteredMonth = ref(monthFromQuery() || moment());
    const prevMonth = ref(null);
    const nextMonth = ref(null);
    const fulfilledItems = ref([]);
    const notFulfilledItems = ref([]);
    const cancelledItems = ref([]);
    const approvedItems = ref([]);
    const activeItems = ref([]);
    const previewInvoice = ref({});
    const filterables = ref(new Filterables({ items: [] }));

    const showPreviewInvoice = (invoice, val) => {
      let showProjectInfo;
      route.query = showProjectInfo;
      route.query.showProjectInfo = val;
      previewInvoice.value = invoice;
    };

    const showPreviewInvoiceUsingKey = (key) => {
      UsersReport.fetchInvoiceData(key).then((response) => {
        showPreviewInvoice(response);
      });
    };

    const hidePreviewInvoice = () => {
      previewInvoice.value = {};
      store.commit('admin/updatePreviewInvoice', { invoice: null });
      return false;
    };

    const updateFilterItems = () => {
      const items = [
        new FilterableItem({
          name: 'Fullfilled Invoices',
          key: 'paidInvoices',
          isCheckbox: false,
          number: fulfilledItems.value.length,
        }),
        new FilterableItem({
          name: 'Unpaid Invoices',
          key: 'unpaidInvoices',
          isCheckbox: false,
          number: notFulfilledItems.value.length,
        }),
        new FilterableItem({
          name: 'Cancelled Invoices',
          key: 'cancelledInvoices',
          isCheckbox: false,
          number: cancelledItems.value.length,
        }),
        new FilterableItem({
          name: 'Approved Invoices',
          key: 'approvedInvoices',
          isCheckbox: false,
          number: approvedItems.value.length,
        }),
      ];

      if (!filterables.value.activeItem) {
        filterables.value.activeItem = 'paidInvoices';
      }

      filterables.value.items = items;

      if (filterables.value.activeItem === 'unpaidInvoices') {
        activeItems.value = report.value.notFulfilled.items;
      } else if (filterables.value.activeItem === 'cancelledInvoices') {
        activeItems.value = report.value.cancelled.items;
      } else if (filterables.value.activeItem === 'approvedInvoices') {
        activeItems.value = report.value.approved.items;
      } else {
        activeItems.value = report.value.fulfilled.items;
      }

      // showPreviewInvoiceUsingKey('1381489150917742596');
    };

    const fetchData = () => {
      console.log('Fetching for', filteredMonth.value);
      state.value.status = RequestStateStatus.Loading;
      UsersReport.fetchInvoice(props.customerDetails.user).then((_report) => {
        report.value = _report;
        fulfilledItems.value = [..._report.fulfilled.items];
        notFulfilledItems.value = [..._report.notFulfilled.items];
        cancelledItems.value = [..._report.cancelled.items];
        approvedItems.value = [..._report.approved.items];
        updateFilterItems();
        state.value.status = RequestStateStatus.Loaded;
      }).catch((err) => {
        state.value.ref = err;
        state.value.status = RequestStateStatus.Error;
      });
    };

    const onSelectedFilteritem = (item) => {
      if (item.isCheckbox) {
        return;
      }

      filterables.value.activeItem = item.key;
      updateFilterItems();
    };

    const onFilterItemToggle = () => {
      updateFilterItems();
    };

    const onInvoiceClick = (invoice) => {
      showPreviewInvoiceUsingKey(invoice.key);
    };

    watch(() => filterables, () => {
      updateFilterItems();
    });

    watch(() => props.customerDetails, () => {
      fetchData();
    });

    onMounted(() => {
      fetchData();
    });

    return {
      state,
      report,
      filteredMonth,
      prevMonth,
      nextMonth,
      filterables,
      onSelectedFilteritem,
      onFilterItemToggle,
      activeItems,
      onInvoiceClick,
      previewInvoice,
      showPreviewInvoiceUsingKey,
      showPreviewInvoice,
      hidePreviewInvoice,
    };
  },
};
</script>
